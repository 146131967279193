
import { computed, defineComponent } from 'vue'
import Page from '@/components/Page.vue'
import Button from '@/components/Form/Button.vue'
import DataTable, { Column } from '@/components/DataTable/DataTable.vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import {
  IAccount, IUserHub,
  TUser,
} from '@/types'
import { ObjPairString } from '@/types/general'
import PageTop from '@/components/PageTop.vue'
import Edit from '@/components/DataTable/Edit.vue'
import moment from 'moment'
import { getItemsWithSamePropertyValue } from '@/globals/javascript/utils/helpers'
import { mixWB } from '@/globals/javascript/utils/mixins'

export default defineComponent({
  components: {
    Page,
    Button,
    DataTable,
    PageTop,
    Edit,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const { isAdmin } = store.getters

    const accounts = computed(
      () => (store.getters['hubStore/accounts'] as IAccount[]).reduce<ObjPairString>(
        (carry, account) => {
          carry[account.id] = account.name
          return carry
        }, {},
      ),
    )
    const onEditUserClick = (userID: string) => {
      router.push({ name: 'User', params: { userID } })
    }

    return {
      onEditUserClick,
      isAdmin,
      columns: computed<Column[]>(() => [
        {
          key: 'fullName',
          title: 'Navn',
          canSort: true,
        },
        ...!route.params.accountID ? [{
          key: 'account',
          title: 'Konto',
          canSort: true,
        }] : [],
        {
          key: 'email',
          title: 'Email',
          canSort: true,
        },
        {
          key: 'numberID',
          title: 'Nummer ID',
          canSort: true,
        },
        {
          key: 'isActive',
          title: 'Status',
          canSort: true,
        },
        {
          key: 'lastSeen',
          title: 'Sidst set',
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
          hide: !isAdmin,
        },
      ]),
      accounts: computed<IAccount[]>(() => store.getters['hubStore/accounts']),
      rows: computed(() => {
        const { accountID } = route.params
        const users: (IUserHub)[] = accountID
          ? store.getters.usersByAccount(accountID)
          : store.getters.hubUsers

        return users.map((user: IUserHub) => {
          const lastSeen = user.hub.lastSeen ? moment(new Date(user.hub.lastSeen)).locale('da-DK').format('D. MMM. YYYY [kl.] HH:mm') : '-'

          return {
            fullName: user.fullName,
            email: user.email,
            isActive: user.hub.isActive,
            numberID: user.numberID,
            id: user.id,
            lastSeen,
            account: accounts.value[user.hub.accountID],
          }
        })
      }),
      account: computed<IAccount | undefined>(() => {
        const { accountID } = route.params
        return accountID && store.getters['hubStore/getAccountByID'](accountID)
      }),
      warnings: computed<string[]>(() => {
        const warnings: string[] = []
        const usersWithSameNumberIDs = getItemsWithSamePropertyValue(store.getters.users as TUser[], 'numberID')
        usersWithSameNumberIDs.forEach((users) => {
          if (users[0].numberID === 0) {
            return
          }
          warnings.push(
            mixWB('%s konti benytter samme nummer ID (%s)',
              [users.length.toString(), users[0].numberID.toString()]),
          )
        })
        return warnings
      }),
    }
  },
})

